html {
    color:#2A87F6;
    background:#000;
  }
  .page{
    width:100%;
    padding: 100px 20px;
    margin: 0px auto;
  }
  
  #crystals {
      position:fixed;
      top:0;
      left:0;
      width:480px;
      height:100%;
  }
  @-webkit-keyframes bg-crystal001 {	100%	{background-position: 0px 900px;}}
  @-moz-keyframes bg-crystal001 {  	100%	{background-position: 0px 900px;}}
  @-o-keyframes bg-crystal001 {		100%	{background-position: 0px 900px;}}
  @keyframes bg-crystal001 {			100%	{background-position: 0px 900px;}}
  #crystals .crystal_01 {
      position:absolute;
      width:100%;
      height:100%;
      background:url(../../../public/crystals/crystals_01.png)repeat-y;
      -webkit-animation: bg-crystal001 30s infinite;
      -moz-animation: bg-crystal001 30s infinite;
      -o-animation: bg-crystal001 30s infinite;
      animation: bg-crystal001 30s infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
      -o-animation-timing-function: linear;
      animation-timing-function: linear;
  }
  @-webkit-keyframes bg-crystal002 {	100%	{background-position: 0px 900px;}}
  @-moz-keyframes bg-crystal002 {  	100%	{background-position: 0px 900px;}}
  @-o-keyframes bg-crystal002 {		100%	{background-position: 0px 900px;}}
  @keyframes bg-crystal002 {			100%	{background-position: 0px 900px;}}
  #crystals .crystal_02 {
      position:absolute;
      width:100%;
      height:100%;
      background:url(../../../public/crystals/crystals_02.png)repeat-y;
      -webkit-animation: bg-crystal002 60s infinite;
      -moz-animation: bg-crystal002 60s infinite;
      -o-animation: bg-crystal002 60s infinite;
      animation: bg-crystal002 60s infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
      -o-animation-timing-function: linear;
      animation-timing-function: linear;
  }
  @-webkit-keyframes bg-crystal003 {	100%	{background-position: 0px -1200px;}}
  @-moz-keyframes bg-crystal003 {  	100%	{background-position: 0px -1200px;}}
  @-o-keyframes bg-crystal003 {		100%	{background-position: 0px -1200px;}}
  @keyframes bg-crystal003 {			100%	{background-position: 0px -1200px;}}
  #crystals .crystal_03 {
      position:absolute;
      width:100%;
      height:100%;
      left:50%;
      margin-left:-180px;
      background:url(../../../public/crystals/crystals_03.png)repeat-y;
      -webkit-animation: bg-crystal003 90s infinite;
      -moz-animation: bg-crystal003 90s infinite;
      -o-animation: bg-crystal003 90s infinite;
      animation: bg-crystal003 90s infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
      -o-animation-timing-function: linear;
      animation-timing-function: linear;
  }
  
  #worldCrystals {
      position:fixed;
      top:150px;
      left:0;
      width:100%;
      height:520px;
  }
  #worldCrystals .crystal_01 {
      position:absolute;
      width:100%;
      height:100%;
      background:url(../../../public/crystals/world_cloud.png)no-repeat -500px 0px;
  }
  @-webkit-keyframes bg-crystal004 {	100%	{background-position: 1198px 0px;}}
  @-moz-keyframes bg-crystal004 {  	100%	{background-position: 1198px 0px;}}
  @-o-keyframes bg-crystal004 {		100%	{background-position: 1198px 0px;}}
  @keyframes bg-crystal004 {			100%	{background-position: 1198px 0px;}}
  #worldCrystals .crystal_02 {
      position:absolute;
      width:100%;
      height:100%;
      background:url(../../../public/crystals/world_crystal01.png)repeat-x;
      -webkit-animation: bg-crystal004 60s infinite;
      -moz-animation: bg-crystal004 60s infinite;
      -o-animation: bg-crystal004 60s infinite;
      animation: bg-crystal004 60s infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
      -o-animation-timing-function: linear;
      animation-timing-function: linear;
  }
  @-webkit-keyframes bg-crystal005 {	100%	{background-position: 1086px 0px;}}
  @-moz-keyframes bg-crystal005 {  	100%	{background-position: 1086px 0px;}}
  @-o-keyframes bg-crystal005 {		100%	{background-position: 1086px 0px;}}
  @keyframes bg-crystal005 {			100%	{background-position: 1086px 0px;}}
  #worldCrystals .crystal_03 {
      position:absolute;
      width:100%;
      height:100%;
      left:50%;
      margin-left:-180px;
      background:url(../../../public/crystals/world_crystal02.png)repeat-x;
      -webkit-animation: bg-crystal005 90s infinite;
      -moz-animation: bg-crystal005 90s infinite;
      -o-animation: bg-crystal005 90s infinite;
      animation: bg-crystal005 90s infinite;
      -webkit-animation-timing-function: linear;
      -moz-animation-timing-function: linear;
      -o-animation-timing-function: linear;
      animation-timing-function: linear;
  }
  